import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Label } from './input'
import { TiRefresh } from 'react-icons/ti'

const Avatar = styled.img`
    height: 110px;
    width: 110px;
    object-fit: contain;
    background-color: white;
    border-radius: 50%;
    padding: 1rem;
    border: 1px solid ${props => props.theme.gray3};
`

const Row = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`

const RefreshIcon = styled(TiRefresh)`
    font-size: 3rem;
    margin-right: 5px;
    opacity: ${props => (props.down ? 1 : 0.7)};
    transition: all ease 0.3s;
    transform: ${props => (props.down ? 'scale(0.9)' : 'scale(1)')};
    cursor: pointer;
`

const PressToChange = styled.div`
    background-color: ${props => props.theme.grayGreen};
    color: ${props => props.theme.gray1};
    font-size: 0.5rem;
    position: absolute;
    left: 104px;
    top: 5rem;
`

function isTouchEnabled() {
    if (typeof window !== 'undefined') {
        return !!window.ontouchstart
    }
    return false
}

export function getRandomAvatar() {
    return `https://avatars.dicebear.com/v2/jdenticon/${'Peronsal-photo' +
        new Date().getTime()}.svg?options[mood][]=happy`
}

export default ({ onChange = () => {} }) => {
    const [avatarUrl, setAvatarUrl] = useState('')
    const [down, setDown] = useState(false)

    const refreshAvatar = () => {
        const value = getRandomAvatar()
        setAvatarUrl(value)
        onChange({ target: { value } })
    }

    useEffect(() => {
        refreshAvatar()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Label>Choose an avatar</Label>
            <Row>
                <Avatar src={avatarUrl} />
                <RefreshIcon
                    down={down ? 1 : 0}
                    onMouseDown={() => setDown(true)}
                    onMouseUp={() => setDown(false)}
                    onTouchStart={() => setDown(true)}
                    onTouchEnd={() => setDown(false)}
                    onClick={() => refreshAvatar()}
                />

                <PressToChange onClick={() => refreshAvatar()}>
                    {isTouchEnabled() ? 'Tap ' : 'Press '} to change
                </PressToChange>
            </Row>
        </>
    )
}
