import React, { useState, useEffect } from 'react'

import * as yup from 'yup' // for everything

import styled from 'styled-components'

import { Container } from './styles'

import Input from './input'

import Button from './button'

import { useMutation } from '@apollo/react-hooks'

import { SAVE_BUSINESS_CARD, REGISTER } from './data-provider'

import AvatarInput from './avatar-input'

import { isEqual } from 'lodash-es'

import { useCurrentUser } from '../hooks'

const Title = styled.h1`
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
    font-weight: 400;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 5rem;
    }
`

const Form = styled.form`
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-bottom: 5rem;
    }
`

const Subtitle = styled.h2`
    margin: 1rem 0;
    font-size: 1rem;
    line-height: 140%;
    font-weight: 300;
    color: ${props => props.theme.gray1};
`

const NavigationRow = styled.div`
    display: flex;

    justify-content: space-between;
`

const Back = styled.div`
    color: ${props => props.theme.gray4};
    font-weight: 800;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 0 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    background-color: ${props => props.theme.gray3};
    border-radius: 2px;
    user-select: none;
    cursor: pointer;
`

const CompletedMessage = styled.div`
    color: ${props => props.theme.gray1};
    font-size: 3rem;
    text-align: center;
    height: 70vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
`

const CompletedMessageText = styled.div`
    margin-bottom: 1rem;
`

const firstPage = {
    message: 'In 30 seconds',
    inputs: [
        {
            label: 'First name',
            name: 'firstName',
            validation: yup
                .string()
                .required('first name is required field')
                .min(3),
            businessCardField: true,
        },
        {
            label: 'Last name',
            name: 'lastName',
            validation: yup
                .string()
                .required('last name is required field')
                .min(3),
            businessCardField: true,
        },
        {
            label: 'Work email',
            type: 'email',
            name: 'email',
            validation: yup
                .string()
                .email()
                .required('email is required field'),
            comment:
                'Cards with public emails (e.g. gmail, outlook, etc) shall  be considered as personal cards - not business cards',
            businessCardField: true,
        },
        {
            label: 'Title',
            name: 'title',
            validation: yup.string().required('title is required field'),
            businessCardField: true,
        },
    ],
}

const secondPage = {
    message:
        'Welcome to verified business card community, the future of networking and prospering.',
    inputs: [
        {
            label: 'Mobile',
            type: 'tel',
            name: 'workCell',
            validation: yup
                .string()
                .required('mobile number is required field')
                .min(6)
                .matches(/^\+\d+$/),
            businessCardField: true,
        },
        {
            label: 'Phone',
            type: 'tel',
            name: 'workPhone',
            validation: yup
                .string()
                .required('phone number is required field')
                .min(6)
                .matches(/^\+\d+$/, 'must be valid phone number'),
            businessCardField: true,
        },
        {
            label: 'Company address',
            name: 'address',
            validation: yup
                .string()
                .required('company address is required field'),
            businessCardField: true,
        },
        {
            label: 'License number',
            name: 'licenseNumber',
            validation: yup.string().notRequired(),
            businessCardField: true,
        },
    ],
}

const thirdPage = {
    message: 'Good job, you are all set',
    inputs: [
        {
            label: 'Choose an avatar',
            name: 'avatarUrl',
            type: 'avatar',
            businessCardField: true,
        },
        {
            label: 'Or upload your own photo',
            name: 'personalPhotoId',
            type: 'file',
            circle: true,
            businessCardField: true,
        },
        {
            label: 'Company logo (optional)',
            name: 'companyLogoId',
            type: 'file',
            freeCrop: true,
            businessCardField: true,
        },
        {
            label: 'Organization name',
            name: 'organizationName',
            comment:
                'Every business card should have the organization name on it',
            validation: yup.string().required(),
            businessCardField: true,
        },
        {
            label: 'Website',
            name: 'website',
            validation: yup
                .string()
                .matches(
                    /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
                    'must be valid website'
                )
                .notRequired(),
            businessCardField: true,
        },
    ],
}

const fourthPage = {
    message: `Let's protect your account`,
    inputs: [
        {
            type: 'password',
            label: 'Password',
            name: 'password',
            validation: yup.string().required(),
            userField: true,
        },
        {
            type: 'password',
            label: 'Confirm your password',
            name: 'passwordConfirmation',
            valueIgnored: true,
            validation: yup
                .string()
                .required()
                .oneOf([yup.ref('password'), null], 'Passwords must match'),
            userField: true,
        },
    ],
}

const Gap = styled.div`
    margin-bottom: 2rem;
`

async function validatePageInputs(index, values, pages) {
    const page = pages[index]
    const shape = page.inputs.reduce((acc, input) => {
        acc[input.name] = input.validation
        return acc
    }, {})
    const schema = yup.object().shape(shape)
    return await schema.validate(values, { abortEarly: false })
}

const initialBusinessCard = {
    firstName: '',
    lastName: '',
    email: '',
    workCell: '',
    workPhone: '',
    address: '',
    licenseNumber: '',
    title: '',
    organizationName: '',
}

const initialPages = [firstPage, secondPage, thirdPage, fourthPage]

export default ({ mode, editCard, onCompleted = () => {} }) => {
    const [pages, setPages] = useState(initialPages)
    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const [loading, setLoading] = useState(false)
    const [completed, setCompleted] = useState(false)
    const [currentPage, setCurrentPage] = useState(pages[currentPageIndex])
    const [currentUser] = useCurrentUser()
    const [user, setUser] = useState({
        email: '',
        password: '',
        passwordConfirmation: '',
    })
    const [businessCard, setBusinessCard] = useState(
        editCard || initialBusinessCard
    )

    const [validationErrors, setValidationErrors] = useState([])

    const [saveBusinessCard, { data }] = useMutation(SAVE_BUSINESS_CARD)

    const [register, { data: userData }] = useMutation(REGISTER)

    useEffect(() => {
        if (pages.length === initialPages.length && currentUser) {
            setPages(p => p.slice(0, -1))
        }
    }, [currentUser, pages])

    useEffect(() => {
        if (completed) {
            onCompleted()
        }
    }, [completed, onCompleted])

    useEffect(() => {
        const saveBusinessCardResponse = data
            ? data.saveBusinessCard
            : { record: {} }
        if (
            saveBusinessCardResponse.record &&
            saveBusinessCardResponse.record._id
        ) {
            let remoteRecord = data.saveBusinessCard.record
            delete remoteRecord.__typename
            updateBusinessCard(remoteRecord)
        }

        if (
            data &&
            data.saveBusinessCard &&
            data.saveBusinessCard.type === 'ERROR' &&
            data.saveBusinessCard.errorPath
        ) {
            setValidationErrors([
                ...validationErrors,
                {
                    path: data.saveBusinessCard.errorPath,
                    message: data.saveBusinessCard.message,
                },
            ])
        }
        // eslint-disable-next-line
    }, [data])

    useEffect(() => {
        setUser({
            ...user,
            email: businessCard.email,
        })
        // eslint-disable-next-line
    }, [businessCard])

    useEffect(() => {
        if (
            userData &&
            userData.register &&
            userData.register.record &&
            userData.register.record._id
        ) {
            saveBusinessCard({
                variables: {
                    businessCard: {
                        ...businessCard,
                        userId: userData.register.record._id,
                    },
                },
            })
        }
        // eslint-disable-next-line
    }, [userData])

    useEffect(() => {
        if (
            isEqual(businessCard, initialBusinessCard) ||
            isEqual(businessCard, editCard)
        )
            return

        if (loading || validationErrors.length) return

        const nextPage = currentPageIndex + 1

        if (nextPage === pages.length) {
            setCompleted(true)
            return
        }

        setCurrentPageIndex(nextPage)

        setCurrentPage(pages[nextPage])
        // eslint-disable-next-line
    }, [loading])

    const updateBusinessCard = data => {
        setBusinessCard({ ...businessCard, ...data })
    }

    const goToNextPage = async () => {
        if (loading) return

        setLoading(true)

        try {
            await validatePageInputs(
                currentPageIndex,
                {
                    ...businessCard,
                    ...user,
                },
                pages
            )

            setValidationErrors([])

            if (currentPageIndex + 1 === pages.length && !currentUser) {
                register({
                    variables: {
                        user: {
                            email: user.email,
                            password: user.password,
                        },
                    },
                })
            } else {
                saveBusinessCard({ variables: { businessCard } })
            }
        } catch (e) {
            setValidationErrors(e.inner)
        } finally {
            setTimeout(function() {
                setLoading(false)
            }, 800)
        }
    }

    const goBack = () => {
        let prevPage = currentPageIndex - 1
        setCurrentPageIndex(prevPage)
        setCurrentPage(pages[prevPage])
    }

    const getValidationError = inputName => {
        const error = validationErrors.find(e => e.path === inputName)
        return error
    }

    const removeValidationError = inputName => {
        setValidationErrors(validationErrors.filter(e => e.path !== inputName))
    }

    return (
        <Container>
            {completed ? (
                <CompletedMessage>
                    <CompletedMessageText>
                        You business card is <strong>here</strong>
                    </CompletedMessageText>
                    <Button
                        href={
                            businessCard.slug ? '/bc/' + businessCard.slug : '/'
                        }
                    >
                        View my business card
                    </Button>
                </CompletedMessage>
            ) : (
                <>
                    {mode !== 'edit' && (
                        <>
                            <Title>Get your business card</Title>
                            <Subtitle>{currentPage.message}</Subtitle>
                        </>
                    )}

                    {businessCard.verified ? (
                        <p>
                            This business card is verified, it can't be modified
                            manually. If you need any modifications email us the
                            details.
                        </p>
                    ) : null}

                    <Form
                        onSubmit={e => {
                            e.preventDefault()
                        }}
                    >
                        {currentPage.inputs.map(config => {
                            if (config.type === 'avatar')
                                return (
                                    <AvatarInput
                                        key={config.label}
                                        disabled={
                                            loading || businessCard.verified
                                        }
                                        onChange={e => {
                                            updateBusinessCard({
                                                [config.name]: e.target.value,
                                            })
                                        }}
                                    />
                                )

                            return (
                                <Input
                                    key={config.label}
                                    disabled={loading || businessCard.verified}
                                    {...config}
                                    error={getValidationError(config.name)}
                                    value={(() => {
                                        if (config.businessCardField)
                                            return businessCard[config.name]
                                        if (config.userField)
                                            return user[config.name]
                                    })()}
                                    onChange={e => {
                                        if (config.businessCardField)
                                            updateBusinessCard({
                                                [config.name]: e.target.value,
                                            })

                                        if (config.userField)
                                            setUser({
                                                ...user,
                                                [config.name]: e.target.value,
                                            })
                                        removeValidationError(config.name)
                                    }}
                                />
                            )
                        })}

                        <NavigationRow>
                            <Button
                                disabled={businessCard.verified}
                                loading={loading}
                                onClick={async () => {
                                    goToNextPage()
                                }}
                            >
                                {mode === 'edit'
                                    ? 'Save'
                                    : 'Get your business card'}
                            </Button>

                            {currentPageIndex > 0 && (
                                <Back onClick={() => goBack()}>Back</Back>
                            )}
                        </NavigationRow>
                        <Gap />
                    </Form>
                </>
            )}
        </Container>
    )
}
