import React from 'react'

import Layout from '../components/layout'

import SEO from '../components/seo'

import CreateBusinessCardForm from '../components/create-business-card-form'

import Footer from '../components/footer'

import Header from '../components/header'

export default () => {
    return (
        <Layout>
            <SEO
                title={'Create your own business card for free!'}
                description={`In 30 seconds, you will never need to print a business card again. Get verified to enjoy the verified badge from verifeco and to protect your business name from misuse.`}
            />
            <Header mode="site-header" />
            <CreateBusinessCardForm />
            <Footer />
        </Layout>
    )
}
